import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type PdfViewer = {
  pdf?: string;
  isFileURL?: boolean;
  base64?: string;
  password?: string;
};
export const PDFViewer = (props: PdfViewer) => {
  const [numPages, setNumPages] = useState(null);
  const [pdfPassword, setPdfPassword] = useState(props?.password || "");

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }
  function onPasswordNeeded(callback: any) {
    let password;
    do {
      password = prompt(
        "This PDF is password-protected. Please enter the password:"
      );
      if (password === null) {
        break;
      }
      setPdfPassword(password);
      try {
        callback(password);
        break;
      } catch (error) {
        console.error(error);
        alert("Incorrect password. Please try again.");
      }
    } while (true);
  }

  const { pdf, isFileURL = false } = props;
  return (
    <Document
      file={isFileURL ? pdf : "data:application/pdf;base64," + pdf}
      onLoadSuccess={onDocumentLoadSuccess}
      onPassword={onPasswordNeeded}
    >
      {Array.from(new Array(numPages), (_, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
  );
};
