import { API_URL } from "../utils/config";
import { apiClient } from "../utils/general";

type APIResponse = {
    code: Number;
    message: string;
    pagination: Array<any>;
    status: string;
};

export type CaptchaRes = APIResponse & {
    body: {
        captchaAnswer:string
        captchaBase64: string;
    };
};

export const getCaptcha = () => {
    return new Promise<CaptchaRes>((resolve, reject) => {
        apiClient({
            method: "GET",
            url: `${API_URL.GETCAPTCHA}`
        })
            .then((response) => {
                resolve(response as CaptchaRes);
            })
            .catch((error) => {
                reject(error);
            });
    });
};