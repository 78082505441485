import { useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "react-image-crop/dist/ReactCrop.css";
import "rc-slider/assets/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { TaxStatusProvider } from "./providers/TaxStatusProvider";
import AppRoutes from "./routes/routes";
import { AxiosInterceptor } from "./utils/AxiosInterceptor";

const App = () => {
  const [showSessionClosed, setShowSessionClosed] = useState(false);

  return (
    <Router>
      <TaxStatusProvider
        values={{
          showSessionClosed,
          setShowSessionClosed,
        }}
      >
        <AxiosInterceptor>
          <AppRoutes />
        </AxiosInterceptor>
      </TaxStatusProvider>
    </Router>
  );
};

export default App;
