import { API_URL } from "../utils/config";
import { apiClient } from "../utils/general";

type APIResponse = {
    code: Number;
    message: string;
    pagination: Array<any>;
    status: string;
};

export type ARStausRes = APIResponse & {
    body: {
        status: string;
    };
};

export type ValidationOtpType = {
    userName: string;
    mobileNumber: string | Number;
    captcha: string | Number;
    isOtpResend: boolean;
    fileName: string;
}

export type ValidateOtpType = {
    userName: string;
    mobileNumber: string | Number;
    otp: string | Number;
    fileName: string;
}

export type ARFileRes = APIResponse & {
    body: {
        fileNameList: [];
    };
};

export const getOTP = (data: ValidationOtpType) => {
    return new Promise<ARStausRes>((resolve, reject) => {
        apiClient({
            method: "POST",
            url: `${API_URL.ARGETOTP}`,
            data
        })
            .then((response) => {
                resolve(response as ARStausRes);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const ResendArOtp = (data: ValidationOtpType) => {
    return new Promise<ARStausRes>((resolve, reject) => {
        apiClient({
            method: "POST",
            url: `${API_URL.ARGETOTP}`,
            data
        })
            .then((response) => {
                resolve(response as ARStausRes);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const validateOTP = (data: ValidateOtpType) => {
    return new Promise<ARStausRes>((resolve, reject) => {
        apiClient({
            method: "POST",
            url: `${API_URL.ARVALIDATEOTP}`,
            data
        })
            .then((response) => {
                resolve(response as ARStausRes);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const getARFileList = () => {
    return new Promise<ARFileRes>((resolve, reject) => {
        apiClient({
            method: "GET",
            url: `${API_URL.ARFILENAME}`,
        })
            .then((response) => {
                resolve(response as ARFileRes);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const downloadPDF = (filename?: string) => {
    return new Promise<Blob>((resolve, reject) => {
      apiClient({
        method: "GET",
        url: `${API_URL.ARDOWNLOAD}/${filename}`,
        headers: {
          "Content-Type": "application/pdf",
        },
        responseType: "blob",
      })
        .then((response) => {
          resolve(response as Blob);
        })
        .catch((error) => {
          console.log(error)
          reject(error);
        });
    });
  };