import { Modal } from "react-bootstrap";
import "./styleForCommonComponent.css";

export const NJSocialHandlesModal = (
  {
    isShowNJSocialHandlesModal,
    setShowSocialModal
  }: any) => {
  return (
    <Modal
      show={isShowNJSocialHandlesModal}
      aria-labelledby="contained-modal-title-vcenter"
      size="lg"
      keyboard={false}
      onHide={() => { setShowSocialModal(false) }}
      centered
    >
      <Modal.Header closeButton>
        <p className="njSocialHandlesModalTitle njsocialHandlesFont">
          <span style={{ fontSize: "30px", display: "inline-block" }}>Beware</span> of fake Telegram, Facebook and other social media entities claiming
          to be owned by NJ Capital Private Limited</p>
      </Modal.Header>
      <Modal.Body>
        <p className="njsocialHandlesFont">
          There may be fake Telegram, Facebook and other social media entities claiming
          to be owned by NJ Capital Private Limited. Please do not get misled by them
          and their luring schemes as NJ Capital Private Limited does not hold any official
          Telegram, Facebook and other social media accounts.
        </p>

        <p className="njsocialHandlesFont">
          NJ Capital Private Limited is <strong>not</strong> present on any social media platforms with any handles/ channels.
        </p>

        <p className="njsocialHandlesFont">
          We request social media users and investors to report fraudulent groups on
          Telegram in case they come across them to
          {" "}
          <a href="mailto: abuse@telegram.org" className="blue">
            abuse@telegram.org
          </a>{" "}or write to us
          at{" "}
          <a href="mailto: compliancenjc@njgroup.in" className="blue">
            compliancenjc@njgroup.in
          </a>{" "}/{" "}
          <a href="mailto: grievance.nbfc@njcapital.in" className="blue">
          grievance.nbfc@njcapital.in
          </a>{" "}.
        </p>
        <p className="njsocialHandlesFont">
          Please visit our official website
          {" "}
          <a href="https://www.njcapital.in"
            target="_blank"
            rel="noopener noreferrer"
            className="blue">
            www.njcapital.in
          </a>{" "}for any authorized and
          official information about our products and offerings.
        </p>
        <p className="njsocialHandlesFont">
          <strong>
            Please Note that if there will be any social media in the name of NJ Capital
            Private Limited ("Company"), same shall be officially communicated on the
            website of the Company.
          </strong>
        </p>
      </Modal.Body>
    </Modal>
  );
};