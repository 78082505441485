import { lazy } from "react";
import "../font/swiss-bt.ttf";

const lazyWithRetry = (componentImport: () => Promise<any>) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("refreshed") || "false"
    );
    try {
      const component = await componentImport();
      window.localStorage.setItem("refreshed", "false");
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        window.localStorage.setItem("refreshed", "true");
        return window.location.reload();
      }
      throw error;
    }
  });

const ApplyLoan = lazyWithRetry(() => import("../screens/Tabs"));

//customer and partner pages routes and component paths also pass in routes
const CustomerDashboard = lazyWithRetry(
  () => import("../screens/partner/dashboard/Dashboard")
);
const CustomerRegistration = lazyWithRetry(
  () => import("../screens/partner/registration/Registration")
);
const CustomerPortfolio = lazyWithRetry(
  () => import("../screens/partner/portfolio/Portfolio")
);
const Eligibility = lazyWithRetry(
  () => import("../screens/partner/eligibility/Eligibility")
);
const ApplyForLoan = lazyWithRetry(
  () => import("../screens/partner/ApplyForLoan")
);
const SelectLas = lazyWithRetry(() => import("../screens/customer/SelectLas"));
const LoanSubmitDone = lazyWithRetry(
  () => import("../screens/customer/LoanSubmitDone")
);
const ProductFeatures = lazyWithRetry(
  () => import("../screens/customer/ProductFeatures")
);
const FetchCas = lazyWithRetry(
  () => import("../screens/customer/stepper-tabs/Fetch-Cas/FetchCasIndex")
);
const KycTabs = lazyWithRetry(
  () => import("../screens/customer/stepper-tabs/Kyc-screens/KycTabs")
);
const SecuritiesPledging = lazyWithRetry(
  () => import("../screens/customer/stepper-tabs/pledging-table/PledgingTable")
);
const EmiCalc = lazyWithRetry(
  () => import("../screens/customer/stepper-tabs/Know-Emi/KnowEmi")
);
const LoanSummary = lazyWithRetry(
  () =>
    import(
      "../screens/customer/stepper-tabs/selected-securities/loan-summary-tabs/LoanSummary"
    )
);
const IndexMandate = lazyWithRetry(
  () => import("../screens/customer/stepper-tabs/submit-mandate/IndexMandate")
);
const CustomerProfile = lazyWithRetry(
  () => import("../screens/customer/reporting/profile/CustomerProfile")
);
const UnpledgeCharges = lazyWithRetry(
  () => import("../screens/customer/reporting/payment-request/UnpledgeCharges")
);
const Payment = lazyWithRetry(
  () => import("../screens/customer/reporting/payment/Payment")
);
const Request = lazyWithRetry(
  () => import("../screens/customer/reporting/payment-request/Request")
);
const FreshCustomerLogin = lazyWithRetry(
  () => import("../screens/customer/FreshCustomerLogin")
);
const LoanStatusReport = lazyWithRetry(
  () => import("../screens/customer/reporting/loan-report/LoanStatusReport")
);
const MandateManage = lazyWithRetry(
  () => import("../screens/customer/reporting/mandate-management/MandateManage")
);
const PartnerRequest = lazyWithRetry(
  () => import("../screens/partner/request/PartnerRequest")
);
const LoanStatusReportPartner = lazyWithRetry(
  () => import("../screens/partner/loan-status-partner/LoanStatusReportPartner")
);
const EmiService = lazyWithRetry(
  () => import("../screens/partner/EMI/EmiService")
);
const EmiCalculator = lazyWithRetry(
  () => import("../screens/partner/EMI/EmiCalculator")
);
const Unpledge = lazyWithRetry(
  () => import("../screens/partner/unpledge/Unpledge")
);
const UnpledgeTable = lazyWithRetry(
  () => import("../screens/partner/unpledge/UnpledgeTable")
);
const UnPledgeCharge = lazyWithRetry(
  () => import("../screens/partner/unpledge/UnPledgeCharge")
);
const BusinessReport = lazyWithRetry(
  () => import("../screens/business/business-report/BusinessReport")
);
const BusinessActual = lazyWithRetry(
  () => import("../screens/business/business-calc-actual/BusinessActual")
);
const PermanentProfileAdd = lazyWithRetry(
  () =>
    import("../screens/customer/address/permanent-address/PermanentProfileAdd")
);
const CorrespondenceProfileAdd = lazyWithRetry(
  () =>
    import(
      "../screens/customer/address/correspondence-address/CorrespondenceProfileAdd"
    )
);
const CustomerCarePartner = lazyWithRetry(
  () => import("../screens/partner/partner-customer-care/CustomerCarePartner")
);
const CustomerCare = lazyWithRetry(
  () => import("../screens/customer/customer_care/CustomerCare")
);
const ChangeNumber = lazyWithRetry(
  () => import("../screens/customer/reporting/profile/ChangeMobileNumber")
);
const ChangeEmailID = lazyWithRetry(
  () => import("../screens/customer/reporting/profile/ChangeEmailId")
);
const PartnerProfile = lazyWithRetry(
  () => import("../screens/partner/profile/PartnerProfile")
);

const PrincipalAddress = lazyWithRetry(
  () =>
    import(
      "../screens/partner/profile/Non-Individual-address-change/PrincipalAddress"
    )
);
const RegisteredOfficeAddress = lazyWithRetry(
  () =>
    import(
      "../screens/partner/profile/Non-Individual-address-change/RegisteredOfficeAddress"
    )
);
const PartnerCorrespondenceAddress = lazyWithRetry(
  () =>
    import(
      "../screens/partner/profile/Individual-address-change/partnerCorrespondenceAddress"
    )
);
const PartnerOfficeAddress = lazyWithRetry(
  () =>
    import(
      "../screens/partner/profile/Individual-address-change/partnerOfficeAddress"
    )
);
const PartnerPermanentAddress = lazyWithRetry(
  () =>
    import(
      "../screens/partner/profile/Individual-address-change/partnerPermanentAddress"
    )
);
const PartnerChangeEmailId = lazyWithRetry(
  () => import("../screens/partner/profile/PartnerChangeEmailId")
);
const PartnerChangeMobileNumber = lazyWithRetry(
  () => import("../screens/partner/profile/PartnerChangeMobileNumber")
);
const CustomerPortfolioShare = lazyWithRetry(
  () => import("../screens/partner/customer-portfolio/CustomerPortfolioShare")
);

const VcipMain = lazyWithRetry(
  () => import("../screens/customer/stepper-tabs/V-CIP/VcipMain")
);

export const onboardRoutes = [
  { path: "/partner/onboard/*", element: ApplyLoan },
];

export const partnerRoutes = [
  {
    path: "/partner/partner/customer/registration",
    element: CustomerRegistration,
  },
  { path: "/partner/partner/customer/portfolio", element: CustomerPortfolio },
  { path: "/partner/partner/customer/eligibility", element: Eligibility },
  { path: "/partner/partner/customer/apply-for-loan", element: ApplyForLoan },
  { path: "/partner/partner/customer/dashboard", element: CustomerDashboard },
  { path: "/partner/partner/customer/request", element: PartnerRequest },
  { path: "/partner/partner/customer/emi-service", element: EmiService },
  { path: "/partner/partner/customer/emi-calculator", element: EmiCalculator },
  {
    path: "/partner/partner/customer/unpledge-charge",
    element: UnPledgeCharge,
  },
  {
    path: "/partner/partner/customer/business-report",
    element: BusinessReport,
  },
  {
    path: "/partner/partner/customer/business-actual",
    element: BusinessActual,
  },
  {
    path: "/partner/partner/customer/partner-loan-status-report",
    element: LoanStatusReportPartner,
  },
  {
    path: "/partner/partner/customer-care",
    element: CustomerCarePartner,
  },
  {
    path: "/partner/partner/profile",
    element: PartnerProfile,
  },
  {
    path: "/partner/partner/principal-address",
    element: PrincipalAddress,
  },
  {
    path: "/partner/partner/registered-office-address",
    element: RegisteredOfficeAddress,
  },
  {
    path: "/partner/partner/permanent-address",
    element: PartnerPermanentAddress,
  },
  {
    path: "/partner/partner/correspondence-address",
    element: PartnerCorrespondenceAddress,
  },
  {
    path: "/partner/partner/office-address",
    element: PartnerOfficeAddress,
  },
  { path: "/partner/partner/change-email", element: PartnerChangeEmailId },
  {
    path: "/partner/partner/change-mobile",
    element: PartnerChangeMobileNumber,
  },
  {
    path: "/partner/partner/customer-portfolio-share",
    element: CustomerPortfolioShare,
  },
];

export const customerRoutes = [
  { path: "/partner/customer/select-las", element: SelectLas },
  { path: "/partner/customer/fetch-cas", element: FetchCas },
  { path: "/partner/customer/know-your-emi", element: EmiCalc },
  { path: "/partner/customer/product-features", element: ProductFeatures },
  { path: "/partner/customer/personal-details", element: KycTabs },
  { path: "/partner/customer/submit-mandate", element: IndexMandate },
  { path: "/partner/customer/loan-submitted", element: LoanSubmitDone },
  { path: "/partner/customer/profile", element: CustomerProfile },
  { path: "/partner/customer/unpledge-table", element: UnpledgeTable },
  { path: "/partner/customer/request", element: Request },
  { path: "/partner/customer/payment", element: Payment },
  { path: "/partner/customer/security-pledging", element: SecuritiesPledging },
  { path: "/partner/customer/unpledge", element: Unpledge },
  { path: "/partner/customer/loan-summary", element: LoanSummary },
  {
    path: "/partner/customer/loan-status-report",
    element: LoanStatusReport,
  },
  {
    path: "/partner/customer/additional-pledging/security-selection",
    element: SecuritiesPledging,
  },
  {
    path: "/partner/customer/mandate-management/create-mandate",
    element: IndexMandate,
  },
  { path: "/partner/customer/permanent-address", element: PermanentProfileAdd },
  {
    path: "/partner/customer/correspondence-address",
    element: CorrespondenceProfileAdd,
  },
  { path: "/partner/customer/mandate-management", element: MandateManage },
  { path: "/partner/customer/customer-care", element: CustomerCare },
  { path: "/partner/customer/change-number", element: ChangeNumber },
  { path: "/partner/customer/change-email", element: ChangeEmailID },
  { path: "/partner/customer/vcip", element: VcipMain }, // NEW PATH ADDED FOR VCIP : REQUEST 73303 (DONE BY GAURAV SHARMA --> gaurav38)
];