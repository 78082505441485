import Spinner from "react-bootstrap/Spinner";

export const LoadingSpinner = () => {
  return (
    <>
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
      />{" "}
    </>
  );
};
