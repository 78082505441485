import React from "react";
import { Table } from "react-bootstrap";
import HomeFooter from "../HomeFooter";
import HomeHeader from "../HomeHeader";
import termLoanPPRImg from "../../../assets/images/termloanppr.jpg";

const TermLoan = () => {
    return (
        <div>
            <HomeHeader />
            <div id="Introduction" className="section business-verticals">
                <div className="container">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1 className="home-main-heading titleHead marginTop-30">
                                {"Loan Against Securities - Term Loan".toUpperCase()}
                            </h1>
                        </div>
                        <div className="col-md-12 col-sm-12 align-self-center">
                            <p>
                                Leverage your investment portfolio, held in both demat and
                                non-demat form, to unlock the full potential of your investments
                                while avoiding unscheduled redemptions and sale of investments.
                                Liquidating your portfolio in an unplanned manner will
                                negatively impact the returns due to possible unfavourable
                                market prices and upfront tax liabilities, all of which ends up
                                causing financial losses to investors and thus, impacts Goal
                                Based investments that the investor may be looking forward to.
                                The LAS - Term Loan product introduced by NJ Capital provides a
                                very quick, convenient way of availing finance against
                                securities held in your account, while still retaining ownership
                                and riding the upside of being invested in the stock market.
                            </p>
                            <p className="mt-5">
                                The EMI based repayment structure also ensures that payments are
                                made in an orderly, planned and affordable manner over the
                                specified period and the liability is continuously brought down.
                            </p>
                            <h6 className="mt-5">
                                The features of the product have been given below :
                            </h6>
                        </div>
                        <div className="row main-bv-table mt-4">
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">1</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div className="bv-table-description">
                                    <p>Loan amount</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        Demat : ₹ 25,000 (Twenty Five Thousand Only) to ₹ 10,000,000
                                        (One Crore Only)
                                    </p>
                                    <p>
                                        Non Demat : ₹ 25,000 (Twenty Five Thousand Only) to
                                        ₹50,00,000 (Fifty Lakhs Only)
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">2</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>Securities acceptable as collateral</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div className="bv-table-description">
                                    <h6>Approved :</h6>
                                    <ul>
                                        <li>Equity MF Schemes</li>
                                        <li>Debt MF Schemes</li>
                                        <li>Hybrid MF Schemes</li>
                                        <li>Fund of Funds</li>
                                        <li>Balanced Funds</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">3</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>Eligibility</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div className="bv-table-description">
                                    <li>The applicant must be a resident of India.</li>
                                    <li>The applicant must be aged above 18 years.</li>
                                    <li>
                                        The applicant must have a bank account and all the KYC
                                        documents in place.
                                    </li>
                                    <li>
                                        The applicant must pledge securities to secure the loan.
                                    </li>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">4</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>Loan Purpose</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div className="bv-table-description">
                                    <li>Agriculture</li>
                                    <li>Business</li>
                                    <li>Property Acquisition and/or Improvement</li>
                                    <li>Education</li>
                                    <li>Medical Expenses</li>
                                    <li> Holidays/Weddings/Family Occasions</li>
                                    <li> Personal Effects (Vehicle/Jewellery, etc.)</li>
                                    <li>Retiring Existing Debt</li>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">5</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>Loan Tenure (Months)</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>6 to 84 (Months)</p>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">6</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>Interest Rates</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        The interest rates can range from 12% p.a to 15.5% p.a, depending on the Loan Tenure. Interest shall be calculated on reducing balance.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">7</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>EMI Due Date</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>Date of Disbursal</th>
                                                <th>EMI Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1st to 15th of the month</td>
                                                <td>25th of the same month</td>
                                            </tr>
                                            <tr>
                                                <td>16th to last date of month</td>
                                                <td>10th of the following month</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">8</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>Margin (%) Required** (through the tenure of loan)</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div className="bv-table-description">
                                    <p>
                                        The Borrower shall be required to maintain the specified
                                        margin at all times and the aggregate Security Value –i.e.,
                                        total value of all the Securities offered by the Borrower
                                        shall be netted off for such Margin and the balance will be
                                        considered for the purpose of actual disbursal. This margin
                                        shall be maintainable every single day and any shortfall
                                        shall be fulfilled immediately either by way of pledging
                                        additional securities or reduction of the loan (by way of
                                        repayment).
                                    </p>
                                    <p>
                                        The indicative margins (as mandated by RBI and/or internal
                                        company policy) required to be maintained are given herein
                                        below -
                                    </p>
                                    <ul>
                                        <li>Approved Equity MF's between 50% - 75% of the NAV</li>
                                        <li>Approved Debt MF's about 20% of the NAV</li>
                                    </ul>
                                    <p>
                                        Please refer to the specific scrip / mutual funds and the
                                        applicable margins{" "}
                                        <a
                                            href={require("../../../assets/download/LTV-Master.pdf")}
                                            target="_blank"
                                            className="faq-btn faq-button-link blue"
                                            rel="noreferrer"
                                            download
                                        >
                                            LTV Master
                                        </a>
                                        . The above margin rates have been mentioned to provide a
                                        general idea of the margin requirements.
                                    </p>
                                    <p>
                                        Such margins can change from time to time depending on the
                                        market conditions and/or internal policy of the company.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">9</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        Notice Period to fulfil Margin Calls (i.e., where Actual
                                        Margin available falls below the Required Margin)
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        Three (3) Business Days. The margin can be restored either
                                        by repaying part of the loan or by offering more securities
                                        as collateral.
                                    </p>
                                    <p>
                                        In case margin shortfall is covered by repaying some part of
                                        the loan, no prepayment charges would be applied on such
                                        amount prepaid.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">10</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        Date of Disposal of Securities (in case of non-receipt of
                                        EMIs)
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        Where the EMI Due Date falls on the 10th of a month and the
                                        due amount remains unpaid, the date of disposal of
                                        securities will be the 25th of the same month. In case the
                                        25th is not a Business Day, then securities will be invoked
                                        immediately on the following Business Day.
                                    </p>
                                    <p>
                                        Where the EMI Due Date falls on the 25th of a month and the
                                        due amount remains unpaid till then the date of invocation
                                        of securities will be the 10th of the following month. In
                                        case if the 10th is not a Business Day, then securities will
                                        be disposed immediately on the following Business Day.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">11</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        Disposal of Securities (in case of occurrence of Event of
                                        Default / Potential Event of Default as defined in the
                                        Agreement other than non-receipt of Instalment)
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        Within a period of Seven (7) Business Days from the date of
                                        service of notice of occurrence of such Event of Default /
                                        Potential Event of Default by NJ Capital Private Limited, if
                                        such Event of Default is not cured or within such additional
                                        time which may be extended at the sole discretion of the
                                        NJC.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">12</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>Processing Fees (%)</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        0.5% of the loan amount subject to a maximum of ₹ 20,000/-
                                        (exclusive of applicable taxes). The same shall be deducted
                                        from the disbursed loan amount.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">13</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>Documentation Charges</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        First Time - ₹ 500/- (Five hundred only) (exclusive of
                                        applicable taxes)
                                    </p>
                                    <p>
                                        Subsequent Loans - ₹ 200/- (Two hundred only) (exclusive of
                                        applicable taxes)
                                    </p>
                                    <p>
                                        The same shall be deducted from the disbursed loan amount.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">14</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>Prepayment Charges</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <Table bordered>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Prepayment</th>
                                                <th>Charges (Excluding applicable taxes)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td style={{ textAlign: "left" }}>Before 12 EMI’s</td>
                                                <td>4%</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td style={{ textAlign: "left" }}>Beyond 12 EMI’s and upto 24 EMI’s</td>
                                                <td>2%</td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td style={{ textAlign: "left" }}>Beyond 24 EMI’s and upto 36 EMI’s</td>
                                                <td>1%</td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td style={{ textAlign: "left" }}>Beyond 36 EMI’s</td>
                                                <td>Nil</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">15</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>Additional Interest - on Delayed Payment</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        1.5% per month or part thereof, on the Outstanding’s, for
                                        the entire period of delay up to the date of realisation.
                                        (Exclusive of applicable taxes)
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">16</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>Dishonour/Bounce Charges</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        ₹ 500/- (Five Hundred Only) (Exclusive of applicable taxes),
                                        for every instance of dishonour.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">17</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>Other Charges ( Pledging / Pledge Release & Invocation) </p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        <strong>For Securities held in Demat form :</strong>
                                    </p>
                                    <p>
                                        As charged by the Depository Participant (DP) from time to time.
                                        Currently the DP, i.e NJ Indiainvest Private Limited,
                                        charges Rs.15/- ( Plus applicable taxes) per ISIN for every
                                        instance of Pledge / Pledge Release. No further costs are imposed on
                                        Invocation.
                                    </p>
                                    <p>
                                        <strong>For Securities held in Physical form :</strong>
                                    </p>
                                    <p>
                                        The Company has tie up with two major Registrar and transfer agents ,
                                        I,e Computer Age Management Services Ltd. (CAMS)
                                        & KFin Technologies Limited (KFin). The charges vary as
                                        provided in below table and are exclusive of applicable taxes.
                                    </p>
                                    <span style={{ display: "block", overflow: "auto" }}>
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th>RTA</th>
                                                    <th>Pledging Charges (Per Instance)</th>
                                                    <th>Pledge Release Charges (Per Instance)</th>
                                                    <th>Invocation Charges (Per Instance)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>CAMS</td>
                                                    <td>₹350</td>
                                                    <td>₹100</td>
                                                    <td>₹100</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>KFin</td>
                                                    <td>-NA-</td>
                                                    <td>₹100</td>
                                                    <td>₹100</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </span>
                                    <p>
                                        The Pledge charges are collected at the time of the disbursal of
                                        Loan and Pledge Release Charges /Invocation charges  are
                                        collected in the event of release or invocation.
                                        It is to be noted that the charges are per instance & hence
                                        multiple ISIN can be Pledged / Released / Invoked in
                                        same instance. However, if same ISIN is Pledged/Released/Invoked in
                                        multiple instances, then multiple charges will be levied.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">18</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p style={{ textAlign: "initial" }}>Delayed ACH/E-Mandate Registration Charges</p>
                                </div>
                            </div>
                            <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div className="bv-table-description">
                                    <p>
                                        Rs. 200/-(exclusive of all applicable taxes) shall be
                                        levied from the second EMI Due Date(as per the Repayment
                                        Schedule) onwards, on every such EMI Due Date, where
                                        the ACH/ E-mandate is not registered, until the ACH/ E-Mandate
                                        of the Borrower is duly registered.
                                    </p>
                                </div>
                            </div>
                            {/* <div className="col-md-1 col-sm-2 col-2 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p className="text-center">19</p>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-10 col-10 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>Prompt Payment Rebate (PPR)</p>
                                </div>
                            </div> */}
                            {/* <div className="col-md-8 col-sm-12 m-0 p-0 d-flex bv-table">
                                <div>
                                    <p>
                                        With effect from 1st August, 2023, for Borrowers availing
                                        loans with tenure of 36 months and above, a prompt payment
                                        rebate has been introduced which will substantially reduce
                                        the interest cost borne by the Borrower. Subject to the
                                        satisfaction of all the conditions provided in the table
                                        below, the Borrower shall be given a rebate amounting to
                                        the value of five EMI’s by way of a refund within 90 days
                                        of the completion of the loan tenure.
                                    </p>
                                    <span style={{ display: "block", overflow: "auto" }}>
                                        <Table bordered>
                                            <tbody>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>Tenure</td>
                                                    <td>36</td>
                                                    <td>48</td>
                                                    <td>60</td>
                                                    <td>72</td>
                                                    <td>84</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: "left" }}>PPR* ( EMI Multiple)</td>
                                                    <td>1</td>
                                                    <td>2</td>
                                                    <td>3</td>
                                                    <td>4</td>
                                                    <td>5</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </span>
                                    <p>
                                        The amount of PPR that shall be refunded to the Borrower
                                        will be equal to the multiple of the value of the EMI.
                                        (illustration provided below).
                                    </p>
                                </div>
                            </div> */}
                        </div>
                        {/* <div className="col-md-12 col-sm-12 align-self-center">
                            <img src={termLoanPPRImg} alt="img" className="img-fluid" />
                        </div> */}
                        <div className="col-12">
                            <h6 className="mt-3">**Margin (%) Required -</h6>
                            <p>
                                If, as a result of price changes or due to any other reason
                                whatsoever, the Actual Margin falls below the Required Margin,
                                then the Borrower will be liable to restore/top-up the margin to
                                Required Margin levels, either by pledging Additional Securities
                                acceptable to the Lender and/or by repaying a portion of the
                                Outstandings to the extent of the shortfall. Margin (%) as
                                applicable on the Pledged Securities, as on date hereof, are
                                annexed herewith. However, the Borrower must note that the
                                Margin requirements may change from time to time during the
                                tenor of the loan - in the event of change in any applicable
                                laws, change in market conditions and / or change in internal
                                company policy. Such changes will be implemented prospectively.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <HomeFooter />
        </div>
    );
};

export default TermLoan;