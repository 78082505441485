export enum TypesOfEntity {
  COMPANY_PVT_LTD = "Company - Pvt. Ltd",
  COMPANY_PUBLIC_LTD = "Company - Public Ltd",
  SOLE_PROPRIETORSHIP = "Sole proprietorship",
  HUF = "HUF",
  PARTNERSHIP_FIRM = "Partnership Firm",
  LLP = "LLP",
}

export type EntityDetailsType = {
  entityName: string;
  typeOfEntity: string;
  tan: string;
  gstIn: string;
  estdRegDate: string;
  estdRegNo: string;
  panFileName: string;
  register: string;
  dateOfRegister: string;
  registerNo: string;
  dateOfFormation: string;
  noOfFormation: string;
  incorporationDate: string;
  incorporationPlace: string;
  cinNo: string;
};

export type EntityDetilsReqProps = {
  cinNo: string;
  entityName: string;
  gstIn: string;
  incorporationPlace: string;
  panFileName: string;
  partnershipRegistered: string;
  registrationDate: string;
  registrationNo: string;
  tan: string;
  typeOfEntity: string;
};

export type GetEntityDetailsProps = {
  email: string;
  entityDetails: EntityDetilsReqProps;
  mobile: string;
  pan: string;
  typeOfEntity?: string;
};
