import { useState, CSSProperties, FocusEvent, useEffect, useRef } from "react";
import { useFormik } from "formik";
import Collapse from "react-bootstrap/Collapse";
import HomeFooter from "../HomeFooter";
import HomeHeader from "../HomeHeader";
import "../corporateGovernance/corporateGovernanceStyle.css"
import { BsChevronDown, BsChevronRight } from "react-icons/bs";
import { Modal, Row, Col, Form } from "react-bootstrap";
import { getCaptcha, CaptchaRes } from "../../../services/common.captcha.service";
import { getOTP, ResendArOtp, validateOTP, getARFileList, downloadPDF } from "../../../services/corporategovernance.service";
import { arReportSchema } from "../../../common/yupExp/schemaValidation";
import { Button } from "../../../common/Button/Button";
import { ErrorMessage } from "../../../common/ErrorMessage";
import { errorFailedMessage } from "../../../common/constants";

const reloadIconStyles = {
  reloadIcon: {
    width: "24px",
    height: "24px",
    border: "1px solid rgba(204, 204, 204, 0.05)",
  } as CSSProperties,
};

const CorporateGovernance = () => {

  const [reportOpen, setReportOpen] = useState(false);
  const [captchaBase64, setCaptchaBase64] = useState("");
  const [modalshow, setModalShow] = useState(false);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isTimer, setIsTimer] = useState(false);
  const [resMobileNumber, setResMobileNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResendOtp, setIsLoadingResendOtp] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [otpMessage, setOtpMessage] = useState(false);
  const [fileName, setFileName] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState("");
  const [time, setTime] = useState(180);
  const [isTimerMsg, setisTimerMsg] = useState(false);
  const [fileNameList, setFileNameList] = useState([]);

  const id = useRef(0);
  const clear = () => {
    window.clearInterval(id.current);
  };

  useEffect(() => {

    // dir.readFiles("../../../assets/download/AR", (err: any, content: any, filename: any, next: any) => {
    //     console.log('processing content of file', filename);
    //     next();
    //   });

    getARFileList()
      .then(({ body, code }) => {
        if (code === 200) {
          setFileNameList(body.fileNameList);
          setErrorMessage("");
        }
      }).catch((err: any) => {
        console.log(err);
      });

    // const directoryPath = path.join("../../../assets/download/AR", 'Documents');
    // readdir("../../../assets/download/AR", (err: any, files: any) => {
    //   if (err)
    //     console.log(err);
    //   else {
    //     console.log("\nCurrent directory filenames:");
    //     // files.forEach(files: any => {
    //     //   console.log(files);
    //     // })
    //   }
    // });

    // readdir("../../../assets/download/AR", (err:any, files:any) => {
    //   if (err)
    //     console.log(err);
    //   else {
    //     console.log("\nCurrent directory filenames:");
    //     // files.forEach(file => {
    //     //   console.log(file);
    //     // })
    //   }
    // });

    // fs.readdir("../../../assets/download/AR", function (err: any, files: any) {
    //   //handling error
    //   if (err) {
    //     return console.log('Unable to scan directory: ' + err);
    //   }
    //   //listing all files using forEach
    //   files.forEach(function (file: any) {
    //     // Do whatever you want to do with the file
    //     console.log(file);
    //   });
    // });
  }, []);

  useEffect(() => {
    if (isTimer) {
      id.current = window.setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    }
    return () => clear();
  }, [isTimer]);

  useEffect(() => {
    if (time === 0) {
      clear();
      setOtpMessage(false);
      setisTimerMsg(true);
      setIsTimer(false);
    }
    if (!modalshow) {
      clear();
      setOtpMessage(false);
      setisTimerMsg(false);
      setIsTimer(false);
    }
  }, [time]);

  const formik = useFormik({
    initialValues: {
      userName: "",
      mobileNumber: "",
      captcha: "",
      otp: "",
      fileName: "",
      isOTPSent: isOTPSent,
    },
    validationSchema: arReportSchema,
    onSubmit: (values) => {
      const data = {
        userName: values?.userName,
        mobileNumber: values?.mobileNumber,
        otp: values?.otp,
        fileName: fileName,
      };
      setIsLoading(true);
      validateOTP(data)
        .then(({ body, code, message }) => {
          if (code === 200) {
            setErrorMessage("");
            // const fileURL = require(`../../../assets/download/AR/${fileName.replace("AR FY ", "AR_").replaceAll(" ", "")}.pdf`);
            // console.log("fileURL ==> " + fileURL);
            // const link: HTMLAnchorElement = document.createElement("a");
            // if (link) {
            //   link.href = fileURL;
            //   link.setAttribute("target", `_blank`);
            //   document.body.appendChild(link);
            //   link.click();
            //   link?.parentNode?.removeChild(link);
            // }

            downloadPDF(fileName as string | undefined)
              .then((res) => {
                const fileURL = window.URL.createObjectURL(res);
                // const fileURL = require(`../../../assets/download/AR/${fileName.replace("AR FY ", "AR_").replaceAll(" ", "")}.pdf`);
                // console.log("fileURL ==> " + fileURL);
                const link: HTMLAnchorElement = document.createElement("a");
                if (link) {
                  link.href = fileURL;
                  link.setAttribute("target", `_blank`);
                  document.body.appendChild(link);
                  link.click();
                  link?.parentNode?.removeChild(link);
                }
              })
              .catch((err) => {
                setErrorMessage(errorFailedMessage);
              })
            setModalShow(false);
          } if (code === 4002) {
            setErrorMessage("Invalid OTP");
          }
          setIsLoading(false);
        }).catch((err) => {
          setIsLoading(false);
          setErrorMessage(errorFailedMessage);
        });
    },
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    resetForm,
    setFieldTouched
  } = formik;

  let showModal = (filename: string) => {
    callCaptcha();
    setIsOTPSent(false);
    setResMobileNumber("");
    setErrorMessage("");
    setOtpMessage(false);
    setIsLoading(false);
    setisTimerMsg(false);
    resetForm();
    setModalShow(true);
    setFileName(filename);
    // clear();
    // setTime(180);
  }

  let callCaptcha = () => {
    getCaptcha()
      .then(({ code, body }: CaptchaRes) => {
        if (code === 200) {
          setCaptchaBase64(body.captchaBase64);
          setCaptchaAnswer(body.captchaAnswer);
          setErrorMessage("");
        }
      })
      .catch(() => {
        setCaptchaBase64("");
        setErrorMessage(errorFailedMessage);
        console.log("error in api call");
      });
  }

  const checkMobileNumber = (e: FocusEvent<HTMLInputElement>) => {
    handleBlur(e);
  };

  const checkUserName = (e: FocusEvent<HTMLInputElement>) => {
    handleBlur(e);
  };

  const checkCaptcha = (e: FocusEvent<HTMLInputElement>) => {
    handleBlur(e);
  };

  const checkOTP = (e: FocusEvent<HTMLInputElement>) => {
    handleBlur(e);
  };

  const validateCaptcha = () => {
    if (captchaAnswer === values.captcha) {
      setErrorMessage("");
      return true;
    } else {
      setErrorMessage("Please enter valid Captcha.");
      return false;
    }
  }

  const validateFormField = () => {
    if (errors.userName) {
      setFieldTouched("userName", true);
      return false;
    } else if (errors.mobileNumber) {
      setFieldTouched("mobileNumber", true);
      return false;
    } else if (errors.captcha) {
      setFieldTouched("captcha", true);
      return false;
    }
    return true;
  }

  const OnGenerateOTP = () => {
    if (!validateFormField()) {
      return
    }
    if (!validateCaptcha()) {
      return;
    }
    const data = {
      userName: values?.userName,
      mobileNumber: values?.mobileNumber,
      captcha: values?.captcha,
      isOtpResend: false,
      fileName: fileName
    };

    setIsLoading(true);
    getOTP(
      data as {
        userName: string,
        mobileNumber: string | Number,
        captcha: string | Number,
        isOtpResend: boolean,
        fileName: string,
      }
    )
      .then(({ body, code, message }) => {
        setIsLoading(false);
        if (code === 200) {
          setResMobileNumber(data.mobileNumber);
          setTime(180);
          setErrorMessage("");
          setIsOTPSent(true);
          setOtpMessage(true);
          setIsTimer(true);
        }
        if (code === 400) {
          setIsLoading(false);
          setErrorMessage(message);
        }
      })
      .catch(() => {
        setIsLoading(false);
        setErrorMessage(errorFailedMessage);
      });
  };

  const onResendOtp = () => {
    const data = {
      userName: values.userName,
      mobileNumber: values.mobileNumber,
      captcha: values.captcha,
      isOtpResend: true,
      fileName: fileName
    };
    if (data) {
      setIsLoadingResendOtp(true);
      ResendArOtp(data)
        .then(({ body, code }) => {
          if (code === 200) {
            setisTimerMsg(false);
            setTime(180);
            setIsTimer(true);
            setOtpMessage(true);
            setIsLoadingResendOtp(false);
            setErrorMessage("");
          }
        })
        .catch((err) => {
          setIsLoadingResendOtp(false);
          setErrorMessage(errorFailedMessage);
        });
    } else {
      setErrorMessage(errorFailedMessage);
    }
  };

  const downloadPOSHPdf = () => {
    const fileURL = require("../../../assets/download/NJ Capital POSH.pdf");
    const link: HTMLAnchorElement = document.createElement("a");
    link.href = fileURL;
    link.setAttribute("target", `_blank`);
    document.body.appendChild(link);
    link.click();
    link?.parentNode?.removeChild(link);
  }

  const messageForOtp = `An OTP has been sent to your entered Mobile ${resMobileNumber && resMobileNumber.slice(-10, -8)}***${resMobileNumber && resMobileNumber.slice(-4)}`;
  return (
    <div>
      <HomeHeader />
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="home-main-heading titleHead marginTop-30">
                {"Corporate Governance".toUpperCase()}
              </h1>
            </div>

            <div className="col-12 marginTop-20">
              <div className="shadowColl">
                <div
                  onClick={() => downloadPOSHPdf()}
                  aria-controls="example-collapse-text"
                  className="colHeader"
                >
                  <p>
                    <strong>POSH</strong>
                  </p>
                </div>
              </div>
              <div className="shadowColl">
                <div
                  onClick={() => setReportOpen(!reportOpen)}
                  aria-controls="example-collapse-text"
                  aria-expanded={reportOpen}
                  className="colHeader"
                >
                  <p>
                    <strong>Annual Report</strong>
                  </p>
                  {reportOpen ? (
                    <BsChevronRight className="returnRightIcon" />
                  ) : (
                    <BsChevronDown className="returnRightIcon" />
                  )}
                </div>
                <Collapse in={reportOpen}>
                  <div id="example-collapse-text" className="colBody">
                    <div className="row">
                      {fileNameList.map((item: any) =>
                        <div className="col-md-6 col-12">
                          <ul className="yearReport">
                            <li>
                              <a
                                href="#0"
                                onClick={() => {
                                  showModal(`${item}`);
                                }}
                                className="m-0 pb-0 align-self-center"
                                rel="noreferrer "
                              >
                                {item}
                              </a>
                            </li>
                          </ul>
                        </div>
                      )}

                      {/* <div className="col-md-6 col-12">
                        <ul className="yearReport">
                          <li>
                            <a
                              href="#1"
                              onClick={() => {
                                showModal("AR FY 2021 - 22");
                              }}
                              className="m-0 pb-0 align-self-center"
                              rel="noreferrer "
                            >
                              AR FY 2021 - 22
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6 col-12">
                        <ul className="yearReport">
                          <li>
                            <a
                              href="#1"
                              onClick={() => {
                                showModal("AR FY 2020 - 21");
                              }}
                              className="m-0 pb-0 align-self-center"
                              rel="noreferrer "
                            >
                              AR FY 2020 - 21
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6 col-12">
                        <ul className="yearReport">
                          <li>
                            <a
                              href="#2"
                              onClick={() => {
                                showModal("AR FY 2019 - 20");
                              }}
                              className="m-0 pb-0 align-self-center"
                              rel="noreferrer "
                            >
                              AR FY 2019 - 20
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6 col-12">
                        <ul className="yearReport">
                          <li>
                            <a
                              href="#3"
                              onClick={() => {
                                showModal("AR FY 2018 - 19");
                              }}
                              className="m-0 pb-0 align-self-center"
                              rel="noreferrer "
                            >
                              AR FY 2018 - 19
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
          <Modal
            show={modalshow}
            onHide={() => {
              setModalShow(false);
            }}
            dialogClassName="modal-100w"
            aria-labelledby="example-custom-modal-styling-title"
            centered={true}
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-custom-modal-styling-title">
                User Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col>
                    <Form.Label><span className="text-danger">*</span>User Name</Form.Label>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Enter User Name"
                        name="userName"
                        disabled={isOTPSent}
                        style={{ textTransform: "uppercase" }}
                        value={values.userName}
                        onChange={handleChange}
                        autoFocus
                        onBlur={(e: FocusEvent<HTMLInputElement>) =>
                          checkUserName(e)
                        }
                        isInvalid={
                          !!errors.userName && touched.userName
                        }
                      />
                      <Form.Text className="text-muted">
                        {touched.userName && errors.userName}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Label><span className="text-danger">*</span>Mobile No</Form.Label>
                    <Form.Group className="mb-3" >
                      <Form.Control
                        type="text"
                        placeholder="Enter Mobile No"
                        name="mobileNumber"
                        disabled={isOTPSent}
                        value={values.mobileNumber}
                        onChange={handleChange}
                        onBlur={(e: FocusEvent<HTMLInputElement>) =>
                          checkMobileNumber(e)
                        }
                        isInvalid={
                          !!errors.mobileNumber && touched.mobileNumber
                        }
                      />
                      <Form.Text className="text-muted">
                        {touched.mobileNumber && errors.mobileNumber}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Row>
                      <Col>
                        <Form.Label style={{ marginBottom: "0" }}>Captcha</Form.Label>
                        <Form.Group className="mb-3" >
                          <img width="150" height="50" src={`data:image/jpeg;base64,${captchaBase64}`} style={{ display: "block" }} />
                        </Form.Group>
                      </Col>
                      <Col style={{ alignSelf: "center", marginTop: "1.2rem", marginLeft: "-1.5rem" }}>
                        <span style={{ display: "inline" }}><img src="data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjUxMiIgdmlld0JveD0iMCAwIDY0IDY0IiB3aWR0aD0iNTEyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im00Ni45MTkgMTEuODQtMS45MjkgMTQuMzkyIDMuNS0xLjQ0NWExNy45ODggMTcuOTg4IDAgMCAxIC0yNC41ODUgMjMuMjcybC00LjUxNyA4Ljk0MWEyNy45OTEgMjcuOTkxIDAgMCAwIDM3Ljc5MS0zNy4yMjhsMy42NjgtMS41NHoiIGZpbGw9IiM5YmM5ZmYiLz48cGF0aCBkPSJtMjMgMTYuNDExYTE3Ljk2IDE3Ljk2IDAgMCAxIDE3LjEtLjQ3bDQuNTE3LTguOTM2YTI3Ljk5MSAyNy45OTEgMCAwIDAgLTM3Ljc5NiAzNy4yMjNsLTMuNjY4IDEuNTQgMTMuOTI4IDYuMzkyIDEuOTI5LTE0LjM5Mi0zLjUgMS40NDVhMTguMDI2IDE4LjAyNiAwIDAgMSA3LjQ5LTIyLjgwMnoiIGZpbGw9IiM5YmM5ZmYiLz48ZyBmaWxsPSIjMWU4MWNlIj48cGF0aCBkPSJtMzIuMDE2IDYxLjAwN2EyOS4wNDggMjkuMDQ4IDAgMCAxIC0xMy4wNzktMy4xMTkgMSAxIDAgMCAxIC0uNDQyLTEuMzQ1bDQuNTE4LTguOTM1YTEgMSAwIDAgMSAxLjM0Mi0uNDQxIDE2Ljk4OSAxNi45ODkgMCAwIDAgMjMuNTg5LTIxLjA2N2wtMi41NzMgMS4wNjFhMSAxIDAgMCAxIC0xLjM3MS0xLjA2MWwxLjkyOS0xNC4zOTJhMSAxIDAgMCAxIDEuNDA4LS43NzZsMTMuOTI4IDYuMzkyYTEgMSAwIDAgMSAtLjAzIDEuODMxbC0yLjcxIDEuMTM4YTI5LjAwNiAyOS4wMDYgMCAwIDEgLTI2LjUwOSA0MC43MTR6bS0xMS4yNzYtNC40NzFhMjYuOTkyIDI2Ljk5MiAwIDAgMCAzNS41NDEtMzYuMzI2Ljk5Mi45OTIgMCAwIDEgLS4wMzctLjc5IDEgMSAwIDAgMSAuNTQ4LS41N2wxLjU2OC0uNjU5LTEwLjYzLTQuODc5LTEuNTE4IDExLjMzNCAxLjktLjc4M2ExIDEgMCAwIDEgMS4zLjUyMyAxOC45ODcgMTguOTg3IDAgMCAxIC0yNS4wNTQgMjQuOTkzeiIvPjxwYXRoIGQ9Im0xNy4wODEgNTMuMTZhMSAxIDAgMCAxIC0uNDE3LS4wOTFsLTEzLjkyOC02LjM5MmExIDEgMCAwIDEgLjAzLTEuODMxbDIuNzEtMS4xMzhhMjguOTkyIDI4Ljk5MiAwIDAgMSAzOS41ODYtMzcuNiAxIDEgMCAwIDEgLjQ0MyAxLjM0NWwtNC41MTggOC45MzVhMSAxIDAgMCAxIC0xLjM0Mi40NDEgMTYuOTg5IDE2Ljk4OSAwIDAgMCAtMjMuNTg5IDIxLjA3NmwyLjU3My0xLjA2MWExIDEgMCAwIDEgMS4zNzEgMS4wNTZsLTEuOTI4IDE0LjM5M2ExIDEgMCAwIDEgLS45OTEuODY3em0tMTEuNDQxLTcuMzUxIDEwLjYzIDQuODc5IDEuNTE4LTExLjMzNC0xLjkuNzgzYTEgMSAwIDAgMSAtMS4zLS41MjMgMTguOTg3IDE4Ljk4NyAwIDAgMSAyNS4wNTQtMjQuOTkzbDMuNjE4LTcuMTU3YTI2Ljk5MiAyNi45OTIgMCAwIDAgLTM1LjU0MSAzNi4zMjYuOTkyLjk5MiAwIDAgMSAuMDM3Ljc5IDEgMSAwIDAgMSAtLjU0OC41N3oiLz48L2c+PC9zdmc+"
                          onClick={() => { callCaptcha() }} id="refreshCaptcha" alt="" style={reloadIconStyles.reloadIcon} /></span>
                      </Col>
                    </Row>

                  </Col>

                  <Col>
                    <Form.Label><span className="text-danger">*</span>Enter Captcha</Form.Label>
                    <Form.Group className="mb-3" >
                      <Form.Control
                        type="text"
                        placeholder="Captcha type here"
                        name="captcha"
                        disabled={isOTPSent}
                        value={values.captcha}
                        onChange={handleChange}
                        onBlur={(e: FocusEvent<HTMLInputElement>) =>
                          checkCaptcha(e)
                        }
                        isInvalid={
                          !!errors.captcha && touched.captcha
                        }
                      />
                      <Form.Text className="text-muted">
                        {touched.captcha && errors.captcha}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    {!isOTPSent &&
                      <Form.Group className="mb-3">
                        <Button
                          isLoading={isLoading}
                          onClick={OnGenerateOTP}
                          name="GET OTP"
                        />
                      </Form.Group>
                    }
                    {isOTPSent &&
                      <>
                        <Form.Group className="mb-3" >
                          <Form.Label><span className="text-danger">*</span>Enter OTP</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="OTP type here"
                            name="otp"
                            value={values.otp}
                            onChange={handleChange}
                            onBlur={(e: FocusEvent<HTMLInputElement>) =>
                              checkOTP(e)
                            }
                            isInvalid={
                              !!errors.otp && touched.otp
                            }
                          />
                          <Form.Text className="text-muted">
                            {touched.otp && errors.otp}
                          </Form.Text>
                        </Form.Group>

                      </>
                    }
                  </Col>
                  <Col>
                    {isOTPSent &&
                      <>
                        <div className="mb-3" style={{ marginTop: "17%" }}>
                          <span className="text-danger">*</span>
                          OTP Expires in : {time}
                        </div>
                      </>
                    }
                  </Col>
                  <div className="mb-2 otpMessage" style={{ color: `green` }}>
                    {otpMessage &&
                      messageForOtp}
                  </div>
                  <div className="mb-2 otpMessage" style={{ color: `red` }}>
                    {isTimerMsg &&
                      "Timed out for entering OTP, please try again."}
                  </div>
                </Row>
                <Row className="mb-3">
                  {isOTPSent &&
                    <>
                      <Col >
                        <Form.Group className="mb-3">
                          <Button
                            style={{ marginLeft: "15%" }}
                            isLoading={isLoading}
                            type="submit"
                            name="SUBMIT"
                          />
                          {/* </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3"> */}
                          <Button
                            style={{ marginLeft: "5%" }}
                            isLoading={isLoadingResendOtp}
                            isDisabled={isTimer}
                            onClick={onResendOtp}
                            name="RESEND OTP"
                          />
                        </Form.Group>
                      </Col>
                    </>
                  }
                </Row>
                <div className="mt-3">
                  {errorMessage && <ErrorMessage message={errorMessage} />}
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <HomeFooter />
    </div>
  );
};
export default CorporateGovernance;