const individualPan = "[A-Za-z]{3}[Pp]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}";
const nonIndividualPan =
  "[A-Za-z]{3}[C|F|H|p|P|h|c|f]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}";
const onlyAlpha = /^[a-zA-Z ]*$/;
const passwordAlpha = /^[A-Za-z0-9]{6,15}$/;
const onlyAlphaWithDot = /^[aA-zZ.'\s]+$/;
const ifscCodeRegex = "[A-Za-z]{4}[0]{1}[A-Z0-9]{6}";
const bothPan =
  "[A-Z]{3}[A|B|C|F|H|G|J|L|T|P|a|b|c|f|h|g|j|l|t|p]{1}[A-Za-z]{1}[0-9]{4}[A-Z]{1}";
const partnerUserId = "[N|n]{1}[J|j]{1}[C|c]{1}[0]{1}[0-9]{4}";
const partnerPassword = /^[A-Za-z0-9@#$_]*$/;
const guardianNameRegex = /^[a-zA-Z .'']*$/;
const bankNameRegex = /^[a-zA-ZZ0-9][a-zA-Z0-9 .]*$/;
const yearlyValueData = /^[1-9]*$/;
const nomineeNameCheck = /^[a-zA-Z .']*$/;
const onlyNumber = /^[0-9]*$/;
const tellResCheck = /^[0-9+-]*$/;
const gstCheck =
  "^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[Zz][0-9A-Za-z]{1}$";
const regexCheck = /^[A-Za-z&#()-/ ,. 0-9']*$/;
const entityNameReg = /^[0-9a-zA-Z ]*$/;
const threeDot = /^[0-9]{3}$/;
const onlyNumberWithDot = /^[0-9.]*$/;
const clientId = "[c|C][0-9]{5}";
const userNameReg = /^[a-zA-Z ]*$/;
const amountResCheck = /^\d*\.?\d*$/;

export {
  individualPan,
  nonIndividualPan,
  onlyAlpha,
  onlyAlphaWithDot,
  ifscCodeRegex,
  bothPan,
  guardianNameRegex,
  bankNameRegex,
  yearlyValueData,
  nomineeNameCheck,
  onlyNumber,
  tellResCheck,
  gstCheck,
  regexCheck,
  entityNameReg,
  onlyNumberWithDot,
  partnerUserId,
  partnerPassword,
  threeDot,
  clientId,
  passwordAlpha,
  userNameReg,
  amountResCheck,
};