import { ReactNode, createContext, useContext } from "react";


interface ITaxStatusProvider {
  showSessionClosed: boolean;
  setShowSessionClosed:(value: boolean) => void;
}

const TaxStatusContext = createContext<ITaxStatusProvider>({
  showSessionClosed: false,
  setShowSessionClosed: (value: boolean) => null
});

type TaxStatusProviderProps = {
  children: ReactNode;
  values?: ITaxStatusProvider;
};

export const TaxStatusProvider = ({
  children,
  values,
}: TaxStatusProviderProps) => {
  return (
    <TaxStatusContext.Provider value={values!}>
      {children}
    </TaxStatusContext.Provider>
  );
};

export const useTaxStatus = () => {
  return useContext(TaxStatusContext);
};
