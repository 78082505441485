import { useEffect, useState } from "react";
import {
  Accordion,
  ButtonGroup,
  Container,
  Nav,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import { BsCaretRightFill } from "react-icons/bs";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import "./homePage.css";

const HomeHeader = () => {
  let navOpen = {
    aboutUs: false,
    productsInformation: false,
    login: false,
    jointPartner: false,
    customerService: false,
    loanRepayment: false,
    lending: false,
    distLoan: false,
  };
  const [isOpen, setIsOpen] = useState(navOpen);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 100);
    });
  }, []);

  const [show, setShow] = useState(false);


  //manage navbar drawer open and close
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div className={scroll ? "main-top-header scrolled" : "main-top-header"}>
      <Navbar collapseOnSelect expand="lg" className="navBar home-header">
        <Container style={{ alignItems: "flex-end" }}>
          <Navbar.Brand>
            <a href="/">
              <img src={logo} alt="logo" className="navbar-logo" />
            </a>
          </Navbar.Brand>
          <span className="nav-side-logo" onClick={handleShow}>
            &#9776;
          </span>
          <Offcanvas show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>NJ CAPITAL</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="sidebar-body">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>ABOUT US</Accordion.Header>
                  <Accordion.Body className="sidebar-link">
                    <Link to="/partner/about/introduction">
                      <Dropdown.Item as="span">Introduction</Dropdown.Item>
                    </Link>
                    <Link to="/partner/about/lineage">
                      <Dropdown.Item as="span">Lineage</Dropdown.Item>
                    </Link>
                    <Link to="/partner/about/team">
                      <Dropdown.Item as="span">Management Team</Dropdown.Item>
                    </Link>
                    <Link to="/partner/about/corporate">
                      <Dropdown.Item as="span">
                        Corporate Governance
                      </Dropdown.Item>
                    </Link>
                    <Link to="/partner/about/advantages">
                      <Dropdown.Item as="span">NJ Advantages</Dropdown.Item>
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header> PRODUCTS INFORMATION</Accordion.Header>
                  <Accordion.Body className="sidebar-link">
                    {/* <Link to="/partner/non-demat-securities">
                      <Dropdown.Item as="span">
                        Loan Against Securities - Non Demat
                      </Dropdown.Item>
                    </Link>
                    <Link to="/partner/demat-securities">
                      <Dropdown.Item as="span">
                        Loan Against Securities - Demat
                      </Dropdown.Item>
                    </Link> */}
                    <Link to="/partner/term-loan">
                      <Dropdown.Item as="span">
                        Loan Against Securities - Term Loan
                      </Dropdown.Item>
                    </Link>
                    <Link to="/partner/consumer-loan">
                      <Dropdown.Item as="span">
                        Loan Against Securities - Consumer Loan
                      </Dropdown.Item>
                    </Link>
                    {/* <Link to="/partner/bajaj-finance">
                      <Dropdown.Item as="span">
                        LAS Flexi Loan from Bajaj Finance Limited
                      </Dropdown.Item>
                    </Link>
                    <Link to="/partner/tata-capital">
                      <Dropdown.Item as="span">
                        Personal Loans from Tata Capital Finacial Services
                        Limited
                      </Dropdown.Item>
                    </Link> */}
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    EXISTING CUSTOMER SERVICES
                  </Accordion.Header>
                  <Accordion.Body className="sidebar-link">
                    <Dropdown.Item
                      onClick={() => {
                        window.open(
                          "https://loans.njcapital.in/ecommon/repaymentNJC.fin?action=getMainView"
                        );
                      }}
                    >
                      Loan Repayment Option
                    </Dropdown.Item>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header> JOIN AS A PARTNER</Accordion.Header>
                  <Accordion.Body className="sidebar-link">
                    <Link to="/partner/" target="_blank">
                      <Dropdown.Item as="span">Join as a Partner</Dropdown.Item>
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header> LOGIN</Accordion.Header>
                  <Accordion.Body className="sidebar-link">
                    <Link to="/partner/partner/login" target="_blank">
                      <Dropdown.Item as="span">Partner Login</Dropdown.Item>
                    </Link>
                    <Link to="/partner/customer/login" target="_blank">
                      <Dropdown.Item as="span">Customer Login</Dropdown.Item>
                    </Link>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Offcanvas.Body>
          </Offcanvas>

          <Nav className="justify-content-end flex-grow-1">
            <Nav.Link
              as="span"
              className="nav-links menuDropDown"
              onMouseEnter={() => setIsOpen({ ...navOpen, aboutUs: true })}
              onMouseLeave={() => setIsOpen(navOpen)}
            >
              <Dropdown>
                <Dropdown.Toggle
                  className="drop-down-btn aboutDropDownMenu"
                  onClick={() =>
                    setIsOpen({ ...navOpen, aboutUs: !isOpen?.aboutUs })
                  }
                >
                  ABOUT US
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    marginTop: "0px",
                    display: !isOpen.aboutUs ? "none" : "block",
                  }}
                  show={isOpen?.aboutUs}
                >
                  <Link to="/partner/about/introduction">
                    <Dropdown.Item as="span">Introduction</Dropdown.Item>
                  </Link>
                  <Link to="/partner/about/lineage">
                    <Dropdown.Item as="span">Lineage</Dropdown.Item>
                  </Link>
                  <Link to="/partner/about/team">
                    <Dropdown.Item as="span">Management Team</Dropdown.Item>
                  </Link>
                  <Link to="/partner/about/corporate">
                    <Dropdown.Item as="span">
                      Corporate Governance
                    </Dropdown.Item>
                  </Link>
                  <Link to="/partner/about/advantages">
                    <Dropdown.Item as="span">NJ Advantages</Dropdown.Item>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Link>
            <Nav.Link
              as="span"
              className="nav-links menuDropDown"
              onMouseEnter={() =>
                setIsOpen({ ...navOpen, productsInformation: true })
              }
              onMouseLeave={() => setIsOpen(navOpen)}
            >
              <Dropdown>
                <Dropdown.Toggle
                  className="drop-down-btn"
                  onClick={() =>
                    setIsOpen({
                      ...navOpen,
                      productsInformation: !isOpen?.productsInformation,
                    })
                  }
                >
                  PRODUCTS INFORMATION
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    marginTop: "0px",
                    display: !isOpen.productsInformation ? "none" : "block",
                  }}
                  show={isOpen?.productsInformation}
                >
                  {/* <Link to="/partner/non-demat-securities">
                    <Dropdown.Item as="span">
                      Loan Against Securities - Non Demat
                    </Dropdown.Item>
                  </Link>
                  <Link to="/partner/demat-securities">
                    <Dropdown.Item as="span">
                      Loan Against Securities - Demat
                    </Dropdown.Item>
                  </Link> */}
                  <Link to="/partner/term-loan">
                    <Dropdown.Item as="span">
                      Loan Against Securities - Term Loan
                    </Dropdown.Item>
                  </Link>
                  <Link to="/partner/consumer-loan">
                    <Dropdown.Item as="span">
                      Loan Against Securities - Consumer Loan
                    </Dropdown.Item>
                  </Link>
                  {/* <Link to="/partner/bajaj-finance">
                    <Dropdown.Item as="span">
                      LAS Flexi Loan from Bajaj Finance Limited
                    </Dropdown.Item>
                  </Link>
                  <Link to="/partner/tata-capital">
                    <Dropdown.Item as="span">
                      Personal Loans from Tata Capital Finacial Services Limited
                    </Dropdown.Item>
                  </Link> */}
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Link>
            <Nav.Link
              as="span"
              className="nav-links menuDropDown"
              onMouseEnter={() =>
                setIsOpen({ ...navOpen, loanRepayment: true })
              }
              onMouseLeave={() => setIsOpen(navOpen)}
            >
              <Dropdown>
                <Dropdown.Toggle
                  className="drop-down-btn"
                  onClick={() =>
                    setIsOpen({
                      ...navOpen,
                      loanRepayment: !isOpen?.loanRepayment,
                    })
                  }
                >
                  EXISTING CUSTOMER SERVICES
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    marginTop: "0px",
                    display: !isOpen.loanRepayment ? "none" : "block",
                  }}
                  show={isOpen?.loanRepayment}
                >
                  <Dropdown.Item
                    onClick={() => {
                      window.open(
                        "https://loans.njcapital.in/ecommon/repaymentNJC.fin?action=getMainView"
                      );
                    }}
                  >
                    Loan Repayment Option
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Link>

            <Nav.Link
              as="span"
              className="nav-links menuDropDown"
              onMouseEnter={() => setIsOpen({ ...navOpen, jointPartner: true })}
              onMouseLeave={() => setIsOpen(navOpen)}
            >
              <Dropdown>
                <Dropdown.Toggle
                  className="drop-down-btn"
                  onClick={() =>
                    setIsOpen({
                      ...navOpen,
                      jointPartner: !isOpen?.jointPartner,
                    })
                  }
                >
                  JOIN AS A PARTNER
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    marginTop: "0px",
                    display: !isOpen.jointPartner ? "none" : "block",
                  }}
                  show={isOpen?.jointPartner}
                >
                  <Link to="/partner/" target="_blank">
                    <Dropdown.Item as="span">Join as a Partner</Dropdown.Item>
                  </Link>
                  <Link to="https://mkt.njwebnest.in/survey/njcapital/" target="_blank">
                    <Dropdown.Item as="span">Inquire</Dropdown.Item>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Link>
            <Nav.Link
              as="span"
              className="nav-links menuDropDown"
              onMouseEnter={() => setIsOpen({ ...navOpen, login: true })}
              onMouseLeave={() => setIsOpen(navOpen)}
            >
              <Dropdown>
                <Dropdown.Toggle
                  className="drop-down-btn loginLink"
                  onClick={() =>
                    setIsOpen({ ...navOpen, login: !isOpen?.login })
                  }
                >
                  LOGIN
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    marginTop: "0px",
                    display: !isOpen.login ? "none" : "block",
                  }}
                  show={isOpen?.login}
                >
                  <Link to="/partner/partner/login" target="_blank">
                    <Dropdown.Item as="span">Partner Login</Dropdown.Item>
                  </Link>
                  <Link to="/partner/customer/login" target="_blank">
                    <Dropdown.Item as="span">Customer Login</Dropdown.Item>
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
};
export default HomeHeader;