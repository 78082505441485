import * as yup from "yup";
import { TypesOfEntity } from "../../types/non-individual/entitydetails";
import { OptionType } from "../component/FormInput";
import { GEOProps } from "../constants";
import { gstCheck, ifscCodeRegex, onlyAlpha, onlyAlphaWithDot, onlyNumber, userNameReg } from "../Regex";

export const personalDetailSchema = yup.object({
  applicantName: yup
    .string()
    .max(100, "Applicant's Name is too long")
    .required("Applicant's Name is required")
    .matches(onlyAlphaWithDot, "only alphabets are allowed for this field"),
  maritalStatus: yup.object().required("Marital Status is required").nullable(),
  dateOfBirth: yup.string().required("Date of Birth is required"),
  gender: yup.object().required("Gender is required").nullable(),
  nationality: yup.object().required("Nationality is required").nullable(),
  occupation: yup.object().required("Occupation is required").nullable(),
  educationQualification: yup
    .object()
    .required("Education Qualification is required")
    .nullable(),
  specifyOccupation: yup.string().when("occupation", {
    is: (status: OptionType) => status?.value === "Other",
    then: yup.string().required("Occupation is required"),
    otherwise: yup.string(),
  }),
  specify: yup.string().when("educationQualification", {
    is: (status: OptionType) => status?.value === "Other",
    then: yup.string().required("Education Qualification is required"),
    otherwise: yup.string(),
  }),
  guardian: yup
    .string()
    .matches(onlyAlpha, "only alphabets are allowed for this field")
    .required("Father/Husband's Name is required"),
  agreeConditions: yup
    .bool()
    .oneOf([true], "you need to accept the terms and conditions"),
  panCardFileName: yup.string().required("Please upload PAN Card Copy"),
});

const GEOLocation = {
  GEO_ID: yup.number().required().nullable(),
  GEO_LVL_ID: yup.number().required().nullable(),
  GEO_NAME: yup.string().required().nullable(),
  PAR_GEO_ID: yup.number().required().nullable(),
};

export const OptionSchema = {
  label: yup.string().required().nullable(),
  value: yup.string().required().nullable(),
};

const citySchema = {
  ...OptionSchema,
  city: yup.object().shape({
    GEO_ID: yup.string().required(),
    GEO_LVL_ID: yup.string().required(),
    GEO_NAME: yup.string().required(),
    PAR_GEO_ID: yup.string().required(),
  }),
};

export const addressProofSchema = {
  officeProof: yup
    .object()
    .shape(OptionSchema)
    .required("Office Address Proof type is required")
    .nullable(),
  addressProofFileName: yup
    .string()
    .required("Please Upload Office Address Proof"),
};

export const addressDetails = {
  office: yup.string().required("Office Address is required"),
  country: yup
    .object()
    .shape(GEOLocation)
    .required("Please select Country")
    .nullable(),
  state: yup
    .object()
    .shape(GEOLocation)
    .required("Please select State")
    .nullable(),
  city: yup
    .object()
    .shape(citySchema)
    .required("Please select City")
    .nullable(),
  pincode: yup
    .string()
    .required("Pin Code is required")
    .max(6, "Pin Code must be exactly 6 digits")
    .min(6, "Pin Code must be exactly 6 digits"),
  correspondenceAddress: yup
    .string()
    .required("Correspondence Address is required"),
  correspondencePincode: yup
    .string()
    .required("Pin Code is required")
    .max(6, "Pin Code must be exactly 6 digits")
    .min(6, "Pin Code must be exactly 6 digits"),
  correspondenceCity: yup
    .object()
    .shape(citySchema)
    .required("Please select City")
    .nullable(),
  correspondenceState: yup
    .object()
    .shape(GEOLocation)
    .required("Please select State")
    .nullable(),
  correspondenceCountry: yup
    .object()
    .shape(GEOLocation)
    .required("Please select Country")
    .nullable(),
};

export const addressWithResidential = {
  residentialAddress: yup.string().required("Residential Address is required"),
  residentialCountry: yup
    .object()
    .shape(GEOLocation)
    .required("Please select Country")
    .nullable(),
  residentialState: yup
    .object()
    .shape(GEOLocation)
    .required("Please select State")
    .nullable(),
  residentialCity: yup
    .object()
    .shape(citySchema)
    .required("Please select City")
    .nullable(),
  residentialPincode: yup
    .string()
    .required("Pin Code  is required")
    .max(6, "Pin Code  must be exactly 6 digits")
    .min(6, "Pin Code  must be exactly 6 digits"),
};

export const residentialWithoutProof = yup
  .object({
    ...addressWithResidential,
    ...addressDetails,
  })
  .required();

export const residentialWithProof = yup
  .object({
    ...addressWithResidential,
    ...addressDetails,
    ...addressProofSchema,
  })
  .required();

export const addressSchemaWithProof = yup
  .object({ ...addressDetails, ...addressProofSchema })
  .required();

export const addressSchemaWithoutProof = yup
  .object({ ...addressDetails })
  .required();

export const bankSchema = {
  ifscCode: yup
    .string()
    .required("IFSC code is required")
    .matches(new RegExp(ifscCodeRegex), "IFCS code is invalid")
    .length(11, "IFSC code is invalid"),
  bankName: yup
    .string()
    .required("Bank Name is required")
    .max(40, "bank name is invalid"),
  accHolderName: yup
    .string()
    .required("Account Holder Name is required")
    .max(50, "Account Holder Name is invalid"),
  branchName: yup
    .string()
    .required("Branch Name is required")
    .max(50, "maximum required 50 characters"),
  accType: yup
    .object()
    .shape(OptionSchema)
    .required("Account Type is required")
    .nullable(),
  country: yup
    .object()
    .shape(GEOLocation)
    .required("Please select country")
    .nullable(),
  state: yup
    .object()
    .shape(GEOLocation)
    .required("Please select state")
    .nullable(),
  city: yup
    .object()
    .shape(citySchema)
    .required("Please select city")
    .nullable(),
  pincode: yup
    .string()
    .required("Pin Code is required")
    .max(6, "Pincode must be exactly 6 digits")
    .min(6, "Pincode must be exactly 6 digits"),
  micrNo: yup
    .string()
    .length(9, "MICR number must be exactly 9 characters")
    .nullable(),
  accNumber: yup
    .string()
    .required("Account Number is required")
    .max(20, "must be more than 20 characters"),
  confirmAccountNumber: yup
    .string()
    .required("Confirm Account Number is required")
    .max(20, "must be more than 20 characters")
    .oneOf([yup.ref("accNumber"), null], "Account Number mismatch"),
};
export const bankPennyDrop = {
  verificationDocumentFile: yup.string().required("verificationDocumentFile"),
  verificationDocumentName: yup
    .object()
    .shape(OptionSchema)
    .required("Please select document name")
    .nullable(),
};

export const bankDetailsSchemaBoth = yup
  .object({
    ...bankSchema,
    ...bankPennyDrop,
  })
  .required();

export const bankSchemaWithoutPenny = yup
  .object({
    ...bankSchema,
  })
  .required();

export const businessExpSchema = yup
  .object({
    loanProductsDealingIn: yup
      .array()
      .min(1, "Select at least one loan product")
      .of(yup.object().shape(OptionSchema)),
    state: yup
      .array()
      .min(1, "Select state")
      .of(yup.object().shape(GEOLocation)),
    city: yup.array().min(1, "Select city").of(yup.object().shape(citySchema)),
    investmentProductsDealingIn: yup
      .array()
      .min(1, "Select investment products")
      .of(
        yup.object().shape({
          label: yup.string().required().nullable(),
          value: yup.string().required().nullable(),
        })
      ),
    yearlyValue: yup.string().when("loanProductsDealingIn", {
      is: (status: OptionType[]) => {
        return status?.filter((val: OptionType) => val?.value === "None")
          .length;
      },
      then: yup.string().max(10, "Yearly Value not more then 10 digit"),
      otherwise: yup.string().required("Yearly value required"),
    }),

    totalInvestments: yup.string().when("investmentProductsDealingIn", {
      is: (status: OptionType[]) => {
        return status?.filter((val: OptionType) => val?.value === "None")
          .length;
      },
      then: yup.string(),
      otherwise: yup
        .string()
        .required("Enter your total investments")
        .max(5, "Total Investments not more then 5 digit"),
    }),
    totalStaff: yup.string().required("Total staff is required"),
    businessExp: yup.object().required("Select business in years").nullable(),
  })
  .required();
const Yup = yup.string;
export const nomineeDetailsSchema = yup
  .object({
    name: yup.string().required("Nominee name is required"),
    relationShipWithNominee: yup
      .string()
      .required("Relationship with applicant is required"),
    mobile: Yup().when("country", {
      is: (status: GEOProps) => status.GEO_NAME === "India",
      then: yup
        .string()
        .required("Mobile No is required")
        .length(10, "Mobile No is invalid for India"),
      otherwise: yup.string().max(20, "Mobile No is invalid for other country"),
    }),
    email: yup
      .string()
      .email("Invalid email format")
      .required("Email is required"),
    address: yup.string().required("Address is required"),
    country: yup
      .object()
      .shape(GEOLocation)
      .required("Please select country")
      .nullable(),
    state: yup
      .object()
      .shape(GEOLocation)
      .required("Please select state")
      .nullable(),
    city: yup
      .object()
      .shape(citySchema)
      .required("Please select city")
      .nullable(),
    pincode: Yup().when("country", {
      is: (status: GEOProps) => status.GEO_NAME === "India",
      then: yup
        .string()
        .required("Registration number is required")
        .length(6, "Pin code is invalid for India"),
      otherwise: yup.string().max(15, "Pin code is invalid for other country"),
    }),
    identityProofFileName: yup
      .string()
      .required("Please upload identity proof"),
  })
  .required();

export const entityDetailsSchema = yup
  .object({
    entityName: Yup().required("Entity name is required"),
    tan: Yup()
      .matches(new RegExp("[A-Za-z]{4}[0-9]{5}[A-Za-z]{1}"), "TAN is invalid ")
      .length(10),
    typeOfEntity: Yup().required("Please select"),
    gstIn: Yup()
      .matches(
        new RegExp(
          "^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}[zZ][0-9A-Za-z]{1}$"
        ),
        "GST is invalid "
      )
      .length(15),
    estdRegDate: Yup().when("typeOfEntity", {
      is: (status: string) => status === TypesOfEntity.SOLE_PROPRIETORSHIP,
      then: yup.string().required("ESTD. reg. date required"),
      otherwise: yup.string(),
    }),
    estdRegNo: Yup().when("typeOfEntity", {
      is: (status: string) => status === TypesOfEntity.SOLE_PROPRIETORSHIP,
      then: yup.string().required("ESTD. reg. number is required"),
      otherwise: yup.string(),
    }),
    register: Yup().when("typeOfEntity", {
      is: (status: string) =>
        status === TypesOfEntity.PARTNERSHIP_FIRM ||
        status === TypesOfEntity.LLP,
      then: yup.string().required("Please select register"),
      otherwise: yup.string(),
    }),
    dateOfFormation: Yup().when("register", {
      is: (status: string) => status === "Yes",
      then: yup.string().required(`Enter Date with "DD/MM/YYYY" format`),
      otherwise: yup.string(),
    }),
    noOfFormation: Yup().when("register", {
      is: (status: string) => status === "Yes",
      then: yup.string().required("Formation number is required"),
      otherwise: yup.string(),
    }),
    incorporationDate: Yup().when("typeOfEntity", {
      is: (status: string) =>
        status === TypesOfEntity.COMPANY_PUBLIC_LTD ||
        status === TypesOfEntity.COMPANY_PVT_LTD,
      then: yup.string().required("Incorporation date is required"),
      otherwise: yup.string().nullable(true),
    }),
    incorporationPlace: Yup().when("typeOfEntity", {
      is: (status: string) =>
        status === TypesOfEntity.COMPANY_PUBLIC_LTD ||
        status === TypesOfEntity.COMPANY_PVT_LTD,
      then: yup.string().required("Incorporation place is required"),
      otherwise: yup.string(),
    }),
    cinNo: Yup()
      .length(21)
      .when("typeOfEntity", {
        is: (status: string) =>
          status === TypesOfEntity.COMPANY_PUBLIC_LTD ||
          status === TypesOfEntity.COMPANY_PVT_LTD,
        then: yup
          .string()
          .required("CIN number is required")
          .length(21, "CIN number length must be 21 digit, alpha, numeric.")
          .matches(
            new RegExp(
              "^([L|U|l|u]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$"
            ),
            "CIN number is invalid"
          ),
        otherwise: yup.string(),
      }),
    panFileName: yup.string().required("Please upload PAN Card Copy"),
  })
  .required();

export const addressProofSchemaNonIn = {
  officeProof: yup
    .object()
    .shape(OptionSchema)
    .required("Office Address Proof type is required")
    .nullable(),
  addressProofFileName: yup
    .string()
    .required("Please Upload Office Address Proof"),
  addressProofFilePath: yup.string(),
};

export const addressDetailNonIndividualSchema = {
  headAddress: yup.string().required("Address is required"),
  headPincode: yup
    .string()
    .required("Pin Code is required")
    .max(6, "Pin Code must be exactly 6 digits")
    .min(6, "Pin Code must be exactly 6 digits"),
  headCity: yup.object().required("Please select city").nullable(),
  headState: yup.object().required("Please select state").nullable(),
  headCountry: yup
    .object()
    .shape(GEOLocation)
    .required("Please select country")
    .nullable(),
  registeredAddress: yup.string().required("Registered Address is required"),
  registeredCountry: yup.object().required("Please select country").nullable(),
  registeredState: yup.object().required("Please select state").nullable(),
  registeredCity: yup.object().required("Please select city").nullable(),
  registeredPincode: yup.string().required("Registered Pin Code is required"),
};

export const addressSchemaNonInWithoutProof = yup
  .object({ ...addressDetailNonIndividualSchema })
  .required();

export const addressSchemaNonBoth = yup
  .object({ ...addressProofSchemaNonIn, ...addressDetailNonIndividualSchema })
  .required();

export const businessDetailsNonIndividualSchema = yup
  .object({
    loanProductsDealingIn: yup
      .array()
      .min(1, "Please select at least one loan product")
      .of(
        yup.object().shape({
          label: yup.string().required().nullable(),
          value: yup.string().required().nullable(),
        })
      ),
    state: yup
      .array()
      .min(1, "Please Select State")
      .of(
        yup.object().shape({
          GEO_ID: yup.string().required(),
          GEO_LVL_ID: yup.string().required(),
          GEO_NAME: yup.string().required(),
          PAR_GEO_ID: yup.string().required(),
        })
      ),
    city: yup.array().min(1, "Please Select City"),
    investmentProductsDealingIn: yup
      .array()
      .min(1, "Please select at least one investment product")
      .of(
        yup.object().shape({
          label: yup.string().required().nullable(),
          value: yup.string().required().nullable(),
        })
      ),
    mobile: yup
      .string()
      .required("Mobile no is required")
      .max(10, "Mobile no must be exactly 10 digits")
      .min(10, "Mobile no must be exactly 10 digits"),
    email: yup.string().email("Invalid email").required("Email is Required"),
    keyContactPerson: yup.string().required("Key Contact Person is required"),
    businessExp: yup
      .object()
      .required("Business experience is required")
      .nullable(),
  })
  .required();

export const listOfPartnerSchema = yup.object().shape({
  users: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required("Name is required"),
        designation: yup.string().required("Designation is required"),
        isMinor: yup.bool(),
        pan: yup.string().when("isMinor", {
          is: (status: boolean) => status === true,
          then: yup.string(),
          otherwise: yup
            .string()
            .required("PAN number is required")
            .matches(
              new RegExp("[A-Za-z]{3}[Pp]{1}[A-Za-z]{1}[0-9]{4}[A-Za-z]{1}"),
              "PAN number is invalid"
            ),
        }),
        shareholding: yup.number().when("designation", {
          is: (status: string) => status === "BO",
          then: yup
            .number()
            .required("Please Enter Shareholding")
            .min(25, "Shareholding must be at least 25")
            .max(100, "Maximum length should be 100"),
          otherwise: yup.number().when("designation", {
            is: (status: string) => status === "Others",
            then: yup
              .number()
              .min(0.01, "Shareholding must be at least 0.01")
              .required("Please Enter Shareholding"),
            otherwise: yup.number(),
          }),
        }),
        signatory: yup.string().required("Signatory is required"),
      })
    )
    .test(
      "shareholding",
      "The total number of shareholding must be 100.",
      (rows = []) => {
        // const total = rows.reduce((acc, row) => {
        //   return acc + (row.shareholding || 0);
        // }, 0);
        // return total === 100;

        // Check if all designations are "Director" or "Employee"
        const allDesignationsAreDirectorOrEmployee = rows.every(
          (row) => row.designation === "Director" || row.designation === "Employee"
        );

        if (allDesignationsAreDirectorOrEmployee) {
          return true; // Skip the validation
        }

        const total = rows.reduce((acc, row) => {
          return acc + (row.shareholding || 0);
        }, 0);

        const isValid = total === 100;

        return isValid;
      }
    ),
});

export const gstSchema = yup.object({
  gstregistered: yup.string().required("Please select GST register or not"),
  gstIn: yup.string().when("gstregistered", {
    is: (status: string) => status === "Yes",
    then: yup
      .string()
      .matches(new RegExp(gstCheck), "GST number is invalid")
      .required("GST number is required")
      .length(15, "GST number length must be 15 digits"),
    otherwise: yup.string(),
  }),
  hsnCode: yup.string().when("gstregistered", {
    is: (status: string) => status === "Yes",
    then: yup
      .string()
      .required("SAC/HSN code is required")
      .matches(new RegExp("\\d{6}"), "SAC/HSN code is invalid")
      .length(6, "SAC/HSN code length must be 6 digits"),
    otherwise: yup.string(),
  }),
});

export const arReportSchema = yup.object({
  userName: yup
    .string()
    .matches(new RegExp(userNameReg), "Please enter a valid User Name.")
    .required("Please enter User Name."),
  mobileNumber: yup
    .string()
    .matches(new RegExp(onlyNumber), "Please enter a valid mobile number.")
    .required("Please enter Mobile No.")
    .min(10, "Please Enter Mobile No of 10 digits.")
    .max(10, "Please Enter Mobile No of 10 digits."),
  captcha: yup
    .string()
    .matches(new RegExp(onlyNumber), "Please enter valid Captcha.")
    .required("Please enter valid Captcha.")
    .min(5, "Please enter valid Captcha.")
    .max(5, "Please enter valid Captcha."),
  otp: yup
    .string()
    .matches(new RegExp(onlyNumber), "Please enter valid OTP.")
    .required("Please enter OTP.")
    .min(6, "Please enter valid OTP.")
    .max(6, "Please enter valid OTP."),
});

export const emiPaymentSchema = yup.object({
  emiAmount: yup.string()
    .required("Please enter emi amount.")
    .matches(new RegExp(/^([0-9]{1,10})(\.[0-9]{1,2})?$/), "Please enter valid emi amount.")
});

export const prePaymentSchema = yup.object({
  prePayment: yup.string()
    .required("Please enter pre - payment amount")
    .matches(new RegExp(/^([0-9]{1,10})(\.[0-9]{1,2})?$/), "Please enter valid pre - payment amount.")
});