import axios, { AxiosResponse } from "axios";
import { getLocalStorage } from "../common/utilities";

const defaultHeaders = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
  Authorization: "",
};

export function apiClient({
  url,
  data = {},
  method = "POST",
  headers = {},
  noHeaders,
  ...rest
}: any): Promise<any> {
  const token = getLocalStorage("token");
  const tokenget = token;
  if (tokenget || null === "" || tokenget || null) {
    delete axios.defaults.headers.common["Authorization"];
  }
  return new Promise((resolve, reject) => {
    defaultHeaders.Authorization = `Bearer ${tokenget && tokenget}`;
    axios({
      method,
      url,
      headers: {
        ...(noHeaders ? {} : defaultHeaders),
        ...headers,
      },
      data,
      ...rest,
    })
      .then((res: AxiosResponse) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
