import { Navigate } from "react-router-dom";
import { getLocalStorage } from "../common/utilities";

//login private routes of partner and customer
const PrivateRoute = ({ children, flow }: any) => {
  const token = getLocalStorage('token');
  const auth = token;

  if (flow === "onboard") {
    return auth ? children : <Navigate to="/partner" />;
  }
  if (flow === "partner") {
    return auth ? children : <Navigate to="/partner/partner/login" />;
  }
  if (flow === "customer") {
    return auth ? children : <Navigate to="/partner/customer/login" />;
  }
};

export default PrivateRoute;
