import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";

export const UseIdle = () => {
  const timeout = 30 * 60 * 1000;
  const promptBeforeIdle = 5000;

  const [timeStatus, setTimeStatus] = useState<string>("Active");
  const [remaining, setRemaining] = useState<number>(timeout);
  const [timeOutModal, setTimeOutModal] = useState<boolean>(false);

  const onIdle = () => {
    setTimeStatus("TIME_OUT");
    setTimeOutModal(false);
  };

  const onActive = () => {
    setTimeStatus("Active");
    setTimeOutModal(false);
  };

  const onPrompt = () => {
    setTimeStatus("Prompted");
    setTimeOutModal(true);
  };
  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  return {
    timeOutModal,
    timeStatus,
    remaining,
    promptBeforeIdle,
    handleStillHere,
  };
};
