import { EType } from "../types/customer/customer";
import { ChangeEventOnchange } from "./constants";
import {
  bankNameRegex,
  entityNameReg,
  guardianNameRegex,
  nomineeNameCheck,
  onlyAlpha,
  onlyNumber,
  onlyNumberWithDot,
  regexCheck,
  tellResCheck,
  yearlyValueData,
  amountResCheck,
} from "./Regex";

export const checkName = (e: ChangeEventOnchange) => {
  const values = e?.target?.value;
  return onlyAlpha.test(values);
};
export const guardianName = (e: ChangeEventOnchange) => {
  const values = e.target.value;
  return guardianNameRegex.test(values);
};

export const bankNameCheck = (e: ChangeEventOnchange) => {
  const values = e?.target?.value;
  return bankNameRegex.test(values);
};

export const yearlyData = (e: ChangeEventOnchange) => {
  const values = e?.target?.value;
  return yearlyValueData.test(values);
};
export const checkNumberOnly = (e: ChangeEventOnchange) => {
  const values = e?.target?.value;
  return onlyNumber.test(values);
};

export const validDate = (e: EType) => {
  const values = Date.parse(e.target.value);
  return values < Date.parse(new Date().toString());
};

export const checkNumberWithDot = (e: EType) => {
  const values = e.target.value;
  if (values[0] === "0") {
    return yearlyValueData.test(values);
  } else {
    return onlyNumberWithDot.test(values);
  }
};
export const nomineeCheck = (e: ChangeEventOnchange) => {
  const values = e.target.value;
  return nomineeNameCheck.test(values);
};
export const tellRes = (e: ChangeEventOnchange) => {
  const values = e.target.value;
  return tellResCheck.test(values);
};
export const registerAddressCheck = (e: EType) => {
  const values = e.target.value;
  return regexCheck.test(values);
};
export const entityNameRegCheck = (e: ChangeEventOnchange) => {
  const values = e.target.value;
  return entityNameReg.test(values);
};
export const otpReplace = (e: EType) => {
  const values = e.target.value;
  const res = values.replace(/[^\w\s]/gi, "");
  return res;
};

export const multiply = (num1: number, num2: number) => {
  const roundNum = num1 * num2;
  return parseFloat(roundNum.toString()).toFixed(2);
};

export const setLocalStorage = (arg1: string, arg2: string | number) => {
    return localStorage.setItem(arg1, arg2.toString());
};

export const getLocalStorage = (arg1: string): string | null => {
  return localStorage.getItem(arg1);
};

export const removeLocalStorage = (arg1: string) => {
  return localStorage.removeItem(arg1);
};

export const clearLocalStorage = () => {
  return localStorage.clear();
};

export const checkAllTrue = (element: boolean) => {
  return element === true;
};

export const encodeString = (input: string): string => {
  let output = "";

  for (let i = 0; i < input.length; i++) {
    const charCode = input.charCodeAt(i) + 10;

    output += String.fromCharCode(charCode);
  }
  return output;
};

export const decodeString = (input: string): string | undefined => {
  let output = "";
  if (input) {
    for (let i = 0; i < input.length; i++) {
      const charCode = input.charCodeAt(i) - 10;
      output += String.fromCharCode(charCode);
    }
    return output;
  }
};

export const amountRes = (e: ChangeEventOnchange) => {
  const values = e.target.value;
  return amountResCheck.test(values);
};

export const checkNameAsPerPan = (name: string) => {
  return /^[a-zA-Z0-9,\- ]*$/.test(name);
};