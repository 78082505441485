import { CSSProperties, MouseEvent } from "react";
import { Button as BootstrapBtn } from "react-bootstrap";
import { SubmitTypeType } from "../../types/customer/customer";
import { LoadingSpinner } from "../component/Spinner";
import "./customBtn.css";

type ButtonProps = {
  isLoading?: boolean;
  type?: "submit" | "reset" | "button";
  onClick?: (e: MouseEvent<HTMLElement, MouseEvent> | SubmitTypeType) => void;
  className?: string;
  style?: CSSProperties;
  name: string;
  isDisabled?: boolean;
  variant?: string;
  id?: string;
};
export const Button = ({
  isLoading = false,
  type = "button",
  onClick,
  className = "sendButton",
  style,
  name,
  isDisabled,
  variant = "primary",
  id,
}: ButtonProps) => {
  return (
    <BootstrapBtn
      variant={variant}
      className={className}
      type={type}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      style={style}
      id={id}
    >
      {isLoading && <LoadingSpinner />}
      {name}
    </BootstrapBtn>
  );
};
