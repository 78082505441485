export type StateOption = {
  readonly value: string;
  readonly label: string;
};

export type CountryOption = {
  readonly value: string;
  readonly label: string;
  readonly isDisabled?: boolean;
};
export type PinOption = {
  readonly value: string;
  readonly label: string;
  readonly isDisabled?: boolean;
};
type Label = {
  label: string;
  value: string;
};
export type ChangeEventOnchange = React.ChangeEvent<
  HTMLTextAreaElement | HTMLInputElement | undefined
>;

export type GEOProps = {
  GEO_ID: number;
  GEO_LVL_ID: number;
  GEO_NAME: string;
  PAR_GEO_ID: number;
};

export enum TaxStatus {
  INDIVIDUAL = "Individual",
  NON_INDIVIDUAL = "Non-Individual",
}
export type RemoveLocalStorageProps = {
  name: any;
};
export const bankAccountTypes: Label[] = [
  { value: "Saving", label: "Saving" },
  { value: "Current", label: "Current" },
  { value: "Others", label: "Others" },
];

export const verificationDocName: Label[] = [
  { value: "CANCELLED_CHEQUE", label: "Cancelled Cheque Copy" },
  { value: "BANK_PASS_BOOK_STATEMENT", label: "Bank Pass Book / Statement" },
];

export const bankAccountTypesForKyc: Label[] = [
  { value: "Saving", label: "Saving" },
  { value: "Current", label: "Current" },
  { value: "Others", label: "OD" },
  { value: "Others", label: "CC" },
];
export const loanProductType: Label[] = [
  { value: "Gold Loan", label: "Gold Loan" },
  { value: "Home Loan", label: "Home Loan" },
  { value: "Personal Loan", label: "Personal Loan" },
  { value: "Vehicle Loan", label: "Vehicle Loan" },
  {
    value: "Consumer Loan",
    label: "Consumer Loan",
  },
  {
    value: "Loan Against Security",
    label: "Loan Against Security",
  },
  { value: "None", label: "None" },
];

export const investmentProductsDealingInType: Label[] = [
  { value: "Mutual Fund", label: "Mutual Fund" },
  { value: "Insurance", label: "Insurance" },
  { value: "Stocks", label: "Stocks" },
  { value: "None", label: "None" },
];

export const maritalTypes: Label[] = [
  { label: "Married", value: "Married" },
  { label: "Unmarried", value: "Unmarried" },
];

export const genderTypes: Label[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
];

export const PanStatusTypes: Label[] = [
  { label: "Individual", value: "Individual" },
  { label: "Non-Individual", value: "Non-Individual" },
];

export const officeAddressProofType = [
  { value: "Utility Bill", label: "Utility Bill" },
  { value: "Rent Deed", label: "Rent Deed" },
  {
    value: "Self attested Utility bill + NOC of the property owner",
    label: "Self attested Utility bill + NOC of the property owner",
  },
];
export const modeOfHoldingOption = [
  { value: "Single Holding", label: "Single Holding" },
  { value: "Joint Holding", label: "Joint Holding" },
  {
    value: "Either Or Survivor",
    label: "Either Or Survivor",
  },
];

export const redirectUrl = {
  select_las: "/partner/customer/select-las",
};

export const CheckAuthUrl = [
  "/partner/partner/login",
  "/partner/customer/login",
  "/partner",
  "/home",
];

export const removeLocalStoragePaths = [
  { name: "token" },
  { name: "module" },
  { name: "draft" },
  { name: "additionalDraft" },
  { name: "mandateDraft" },
  { name: "mandateType" },
  { name: "linkLogin" },
  { name: "draft" },
  { name: "accountStatus" },
  { name: "additionalLoanTrnId" },
  { name: "taxStatus" },
  { name: "appStatus" },
  { name: "insertAddReportData" },
  {name:"insertAddReportBeforeFin"},
  { name: "vcipReportsLoanTrnId" },
  { name: "vcipReportsDraft" }
];

export const errorFailedMessage : string = "Something went wrong, Please try again later."