import axios from "axios";
import { useEffect } from "react";
import { useTaxStatus } from "../providers/TaxStatusProvider";

const AxiosInterceptor = ({ children }: any) => {
  const { setShowSessionClosed } = useTaxStatus();

  useEffect(() => {
    const resInterceptor = (response: any) => {
      return response;
    };
    const errInterceptor = (error: any) => {
      if (error?.response?.status === 401) {
        setShowSessionClosed(true);
      }
      return Promise.reject(error);
    };

    const interceptor = axios.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => axios.interceptors.response.eject(interceptor);
  }, [setShowSessionClosed]);
  return children;
};

export default axios;
export { AxiosInterceptor };
