import { CSSProperties, useEffect } from "react";
import { Bars } from "react-loader-spinner";

//spinner style
const loaderStyles = {
  bg: {
    position: "fixed",
    height: "100%",
    width: "100%",
    zIndex: "9999",
    left: 0,
    top: 0,
    background: "#fff",
  } as CSSProperties,
  loaderWrapper: {
    position: "fixed",
    height: "100%",
    width: "100%",
    zIndex: "9999",
    left: 0,
    top: 0,
    background: "rgba(0, 0, 0, 0.5)",
  } as CSSProperties,
  loaderContainer: {
    top: "50%",
    left: 0,
    right: 0,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    transform: "translateY(-50%)",
    position: "absolute",
  } as CSSProperties,
};

export const Spinner = () => {
  return (
    <div style={loaderStyles.bg}>
      <div style={loaderStyles.loaderWrapper}>
        <div style={loaderStyles.loaderContainer}>
          <Bars
            height="80"
            width="80"
            color="#0563c3"
            ariaLabel="bars-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      </div>
    </div>
  );
};
